import Flexbox from "./Flexbox";
import Spacebox from "./styles/Spacebox";
import '../css/input.css'
import { isMobile } from "react-device-detect";

const Layout = ({ children }) => {
    return (
        <div style={{ width: '100%', minHeight: '100vh', backgroundColor: 'var(--primary)' }} className="layout">
            <Spacebox padding="20px" />
            <div style={{ borderRadius: '10px', padding: isMobile ? '20px' : '20px 40px', background: 'white' }} className="max-width margin-auto">
                {children}
            </div>
            <Spacebox padding="20px" />
            <div className="help">
                <Flexbox alignItems="center">
                    <img src="/svgs/help.svg" alt="help" style={{filter: 'invert(1)'}} />
                    <Spacebox padding="5px" />
                    <span style={{color: 'white'}}>Help</span>
                </Flexbox>
            </div>
        </div>
    );
}

export default Layout;