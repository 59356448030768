import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#40496b'
      },
      secondary: {
        main: '#7badf9'
      }
    },

    typography: {
      fontFamily: "Karla",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightBold: 800,
      fontSize: 20
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path="/" element={ <Home />} />
          <Route exact path="*" element={ <Home />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
